@charset "UTF-8";
/*=============================================================
 04_modules
=============================================================*/
/*!  box
================================================ */
.mod_box01 {  border-top: 2px solid #3aabd2; }

.mod_box01_in {  padding: 23px 15px 30px; box-sizing: border-box; }

@media all and (min-width: 600px) {  .mod_box01_in {  width: 1000px; margin: 0 auto; padding: 23px 20px 70px; } }

/*
#styleguide
.mod_box01 ＆.mod_box01_in

SP：padding: 23px 15px 30px;
PC：padding: 23px 20px 70px; ＆ 横幅960px(1000px) ＆ センタリング
共通：上ボーダー ＆ box-sizing: border-box;
1カラム時の<section>での使用を前提。

```
<section id="hoge" class="secPics mod_box01">
	<div class="mod_box01_in">
		<p style="background:#ddd">コンテンツ部分に背景色を付けるとこんな感じ。<br><br><br><br><br><br><br><br></p>
	</div>
</section>
<!-- /#hoge -->
```
*/
/*!  tit
================================================ */
/*
#styleguide
mod_tit01

※テンプレからコピっただけ

```
<p class="mod_tit01">mod_tit01</p>
```
*/
/*!  column
================================================ */
.mod_column01 {  margin: -16px -10px 0; overflow: hidden; }

.mod_column01 .item {  float: left; margin-top: 16px; padding: 0 10px; box-sizing: border-box; }

.mod_column01 .item a {  display: block; }

.mod_column01 .item a .pic {  margin-bottom: 8px; }

.mod_column01 .item a .name {  margin-bottom: 8px; color: #3aabd2; font-size: 1.4rem; font-weight: 700; line-height: 1.43; }

.mod_column01 .item a .txt {  font-size: 1.3rem; line-height: 1.38; }

.mod_column01.sp_col2 .item {  width: 50%; }

.mod_column01.sp_col3 .item {  width: 33.33%; }

.mod_column01.sp_col4 .item {  width: 25%; }

@media all and (min-width: 600px) {  .mod_column01 {  margin: -50px -20px 0; }
  .mod_column01 .item {  margin-top: 50px; padding: 0 20px; }
  .mod_column01 .item a .pic {  margin-bottom: 8px; }
  .mod_column01 .item a .name {  margin-bottom: 8px; font-size: 1.8rem; line-height: 1.5; }
  .mod_column01 .item a .txt {  font-size: 1.5rem; line-height: 2; }
  .mod_column01.pc_col3 .item {  width: 33.33%; }
  .mod_column01.pc_col4 .item {  width: 25%; }
  .mod_column01.pc_col5 .item {  width: 20%; } }

/*
#styleguide
カラムレイアウト

<ul><li>での使用を想定。
一応、別カラム数にも対応できるようにしてます。


```
<ul class="list mod_column01 sp_col2 pc_col4">
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
</ul>
<br>
<ul class="list mod_column01 sp_col3 pc_col5">
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
</ul>
```
*/
/*!  button
================================================ */
.mod_btn01 a {  position: relative; display: block; width: 100%; height: 45px; padding: 12px 25px; background: #3aabd2; color: #fff; font-size: 1.6rem; line-height: 1.5; text-align: center; text-decoration: none; box-sizing: border-box; }

.mod_btn01 a::after {  position: absolute; top: 50%; right: 25px; width: 12px; height: 15px; content: ''; margin-top: -7px; background: url(../img/common/ico_arrow01_white.png) no-repeat; background-size: cover; }

@media all and (min-width: 600px) {  .mod_btn01 {  text-align: right; }
  .mod_btn01 a {  display: inline; width: auto; height: auto; padding: 0 24px 0 0; background: none; color: #3aabd2; font-size: 2rem; text-align: right; }
  .mod_btn01 a::after {  right: 3px; background-image: url(../../img/common/ico_arrow01_blue.png); transition: all 0.3s linear; }
  .mod_btn01 a:hover::after {  right: 0; } }

/*
#styleguide
共通ボタン①

SP：青背景・白抜き文字
PC：背景なし・右寄せ
<p>タグでの使用を前提。

```
<p class="btn mod_btn01"><a href="#">〇〇の一覧へ</a></p>
```
*/
/*!  pager
================================================ */
.mod_pagination01 {  display: block; padding: 20px 0; text-align: center; font-size: 0; margin: -10px; }

.mod_pagination01 li {  display: inline-block; width: 30px; margin: 10px; }

.mod_pagination01 li a, .mod_pagination01 li span {  display: block; line-height: 1; font-size: 1.2rem; color: #353536; padding: 7px 0 5px; box-sizing: border-box; border: 2px solid #353536; background-color: #fff; text-decoration: none; }

.mod_pagination01 .prev {  width: 50px; margin-right: 15px; }

.mod_pagination01 .next {  width: 50px; margin-left: 15px; }

.mod_pagination01 .current {  color: #fff; background-color: #353536; }

/*
#styleguide
mod_pagination01

※テンプレからコピっただけ

```
<div class="mod_pagination01">
	<ul class="links">
		<li class="prev"><a href="">前へ</a></li>
		<li><a href="">1</a></li>
		<li class="current"><span>2</span></li>
		<li><a href="">3</a></li>
		<li class="next"><a href="">次へ</a></li>
	</ul>
</div>
```
*/
