@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  column
================================================ */
.mod_column01 { 
	margin: -16px -10px 0;
	overflow: hidden;
	.item { 
		float: left;
		margin-top: 16px;
		padding: 0 10px;
		box-sizing: border-box;
		a { 
			display: block;
			.pic {  margin-bottom: 8px; }
			.name { 
				margin-bottom: 8px;
				color: $blue;
				font-size: 1.4rem;
				font-weight: 700;
				line-height: 1.43;
			}
			.txt { 
				font-size: 1.3rem;
				line-height: 1.38;
			}
		}
	}
	&.sp_col2 .item {  width: 50%; }
	&.sp_col3 .item {  width: 33.33%; }
	&.sp_col4 .item {  width: 25%; }
	@media all and (min-width: 600px) { 
		margin: -50px -20px 0;
		.item { 
			margin-top: 50px;
			padding: 0 20px;
			a { 
				.pic {  margin-bottom: 8px; }
				.name { 
					margin-bottom: 8px;
					font-size: 1.8rem;
					line-height: 1.5;
				}
				.txt { 
					font-size: 1.5rem;
					line-height: 2;
				}
			}
		}
		&.pc_col3 .item {  width: 33.33%; }
		&.pc_col4 .item {  width: 25%; }
		&.pc_col5 .item {  width: 20%; }
	}
}
/*
#styleguide
カラムレイアウト

<ul><li>での使用を想定。
一応、別カラム数にも対応できるようにしてます。


```
<ul class="list mod_column01 sp_col2 pc_col4">
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
</ul>
<br>
<ul class="list mod_column01 sp_col3 pc_col5">
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
	<li class="item"><a href="#">
		<p class="pic"><img class="alpha" src="https://placehold.jp/270x180.png" alt="タイトルが入ります。タイトルが入ります。"></p>
		<h3 class="name">タイトルが入ります。タイトルが入ります。</h3>
		<p class="txt">テキストが入ります。テキストが入ります。</p>
	</a></li>
</ul>
```
*/