@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  pager
================================================ */
.mod_pagination01 { 
	display: block;
	padding: 20px 0;
	text-align: center;
	font-size: 0;
	margin: -10px;
	li { 
		display: inline-block;
		width: 30px;
		margin: 10px;
		a,span { 
			display: block;
			line-height: 1;
			font-size: 1.2rem;
			color: $gray;
			padding: 7px 0 5px;
			box-sizing: border-box;
			border: 2px solid $gray;
			background-color: #fff;
			text-decoration: none;
		}
	}
	.prev { 
		width: 50px;
		margin-right: 15px;
	}
	.next { 
		width: 50px;
		margin-left: 15px;
	}
	.current { 
		color: #fff;
		background-color: $gray;
	}
}
/*
#styleguide
mod_pagination01

※テンプレからコピっただけ

```
<div class="mod_pagination01">
	<ul class="links">
		<li class="prev"><a href="">前へ</a></li>
		<li><a href="">1</a></li>
		<li class="current"><span>2</span></li>
		<li><a href="">3</a></li>
		<li class="next"><a href="">次へ</a></li>
	</ul>
</div>
```
*/