@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  button
================================================ */
.mod_btn01 { 
	a { 
		position: relative;
		display: block;
		width: 100%;
		height: 45px;
		padding: 12px 25px;
		background: $blue;
		color: #fff;
		font-size: 1.6rem;
		line-height: 1.5;
		text-align: center;
		text-decoration: none;
		box-sizing: border-box;
		&::after { 
			position: absolute;
			top: 50%;
			right: 25px;
			width: 12px;
			height: 15px;
			content: '';
			margin-top: -7px;
			background: url(../img/common/ico_arrow01_white.png) no-repeat;
			background-size: cover;
		}
	}
	@media all and (min-width: 600px) { 
		text-align: right;
		a { 
			display: inline;
			width: auto;
			height: auto;
			padding: 0 24px 0 0;
			background: none;
			color: $blue;
			font-size: 2rem;
			text-align: right;
			&::after { 
				right: 3px;
				background-image: url(../../img/common/ico_arrow01_blue.png);
				@include trans;
			}
			&:hover { 
				&::after { 
					right: 0;
				}
			}
		}
	}
}
/*
#styleguide
共通ボタン①

SP：青背景・白抜き文字
PC：背景なし・右寄せ
<p>タグでの使用を前提。

```
<p class="btn mod_btn01"><a href="#">〇〇の一覧へ</a></p>
```
*/