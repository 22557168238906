@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  box
================================================ */
.mod_box01 { 
	border-top: 2px solid $blue;
	&_in { 
		padding: 23px 15px 30px;
		box-sizing: border-box;
	}
	@media all and (min-width: 600px) { 
		&_in { 
			width: 1000px;
			margin: 0 auto;
			padding: 23px 20px 70px;
		}
	}
}
/*
#styleguide
.mod_box01 ＆.mod_box01_in

SP：padding: 23px 15px 30px;
PC：padding: 23px 20px 70px; ＆ 横幅960px(1000px) ＆ センタリング
共通：上ボーダー ＆ box-sizing: border-box;
1カラム時の<section>での使用を前提。

```
<section id="hoge" class="secPics mod_box01">
	<div class="mod_box01_in">
		<p style="background:#ddd">コンテンツ部分に背景色を付けるとこんな感じ。<br><br><br><br><br><br><br><br></p>
	</div>
</section>
<!-- /#hoge -->
```
*/