@import "../01_setting/_var.scss";
@import "../01_setting/_mixin.scss";
/*!  tit
================================================ */
.mod_tit01 { 
	@media all and (min-width: 600px) { 
	}
}
/*
#styleguide
mod_tit01

※テンプレからコピっただけ

```
<p class="mod_tit01">mod_tit01</p>
```
*/